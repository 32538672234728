import React, { useEffect, useState } from "react";
import { useParams, useHistory, useLocation, Link, Redirect } from "react-router-dom";
import styles from "./GalleryPage.module.css";
import Gallery from "../../Components/Gallery/Gallery";
import { apiRoutesUrls } from "Api/ApiNames";
import { LoaderPage } from '../../Components/LoaderPage/loaderPage';
import translation from "../../Components/Helpers/translation";

import logoFundusze from '../../assets/image/footer/fundusze.png';
import logoFundusze_en from '../../assets/image/footer/fundusze_en.png';
import logoPolska from '../../assets/image/footer/polska.png';
import logoPolska_en from '../../assets/image/footer/polska_en.png';
import logoMinisterstwo from '../../assets/image/footer/MKiDN_pl_2023.png';
import logoMinisterstwo_en from '../../assets/image/footer/MKiDN_en_2023.png';
import logoUe from '../../assets/image/footer/UE-PL.svg';
import logoUe_en from '../../assets/image/footer/UE-EN.svg';
import ErrorFrom from "../../Components/ErrorForm/errorFrom";
import { Helmet } from "react-helmet";
import Cookie from "../Cookie/Cookie";
import { typeOfCategoryToLink } from "../../Components/Helpers/selectType";
import usePrevious from "../../Components/Helpers/usePrevious";
import slug from "slug"
import queryString from "query-string";

function GalleryPage() {
    let history = useHistory();
    let location = useLocation()
    let { lang, type, id, page, children } = useParams()

    const [modalOpened, setModalOpened] = useState(false)
    const [data, setData] = useState(null);
    const [galleryData, setGalleryData] = useState(null);
    const [galleryThumbData, setGalleryThumbData] = useState(null);
    const [showContent, setShowContent] = useState(false)
    const [isActive, setActive] = useState(location.hash === "#nomenu" ? false : true);
    const [oldId,] = useState(id)
    const [humdrumLink, setHumdrumLink] = useState(null)
    id = parseInt(id);

    const prevId = usePrevious(id)

    const handleToggle = () => {
        setActive(!isActive);

        if (isActive) {
            history.replace({ pathname: `${location.pathname.split("#")[0]}#nomenu` })
            location.hash = "#nomenu"
        } else {
            history.replace({ pathname: `${location.pathname.split("#")[0]}` })
            location.hash = ""
        }
    };

    const handleToggleMobile = () => {
        if (window.innerWidth < 1000) {
            handleToggle();
        }
    }

    function handleBack(event) {

        if (location.state !== undefined) {
            history.goBack()
        } else {
            history.push(`/${lang}`)
            window.location.reload(true);
        }
    }

    function keyDown(event) {
        if (event.key === "Escape") {
            history.goBack()
        }
    }

    function linkToCommposer(composer) {
        var slug = require('slug')
        let result = slug(composer.name);

        return `https://portalmuzykipolskiej.pl/pl/osoba/${composer.id}-${result}`
    }

    function linkToComposition(composer) {
        var slug = require('slug')
        let result = slug(composer.name);
        return linkToCommposer(composer) + 'kompozycje/' + result
    }

    if (window.innerWidth < 501) {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
    }

    const formFocus = () => {
        setTimeout(() => {
            document.getElementById("nameAndSurname").focus()
        }, 100)
    }

    const setSelected = (item, type, children) => {
        return typeOfCategoryToLink({ 'id_object': type === "musicalprint_source" ? item.id_musicalprint_source : item.id_manuscript, "type": type, "title": `${item.name ? `${item.name}, ` : ''}${item.standardized_title ? item.standardized_title : item.main_standardized_title}` }, lang, children)
    }

    const createSearchLink = (fieldText, field) => {
        let search_fields = [{ "field": field, "contains": 1, "field_text": fieldText }]

        search_fields = JSON.stringify(search_fields)

        return `/${lang}/szukaj/values?keyword=&${queryString.stringify({ search_fields }, { arrayFormat: 'bracket' })}`
    }

    useEffect(() => {
        setData(null)
        setShowContent(false)

        const selectEndpoitByType = () => {
            switch (type) {
                case "druki-muzyczne":
                    return `${apiRoutesUrls.getUrlFirstEditionsArticleById}${id}${children ? `/${children}` : ''}?lang=${lang}`;
                case "rekopisy":
                    return `${apiRoutesUrls.getUrlManuscriptsArticleById}${id}${children ? `/${children}` : ''}?lang=${lang}`;
                case "klocki-introligatorskie":
                    return `${apiRoutesUrls.getUrlConvoluteArticleById}${id}${children ? `/${children}` : ''}?lang=${lang}`;
                default:
                    return null;
            }
        };

        const selectImageByType = (item, size) => {
            switch (type) {
                case "druki-muzyczne":
                    return `${size ? item.file_name_medium : item.file_name}`;
                case "rekopisy":
                    return `${apiRoutesUrls.getImageManuscriptsArticleById}/${id}${size ? `/${size}` : ''}/${item.file_name}`;
                case "klocki-introligatorskie":
                    return `${apiRoutesUrls.getImageConvoluteArticleById}/${id}${size ? `/${size}` : ''}/${item.file_name}`;
                default:
                    return null;
            }
        };

        const createGalleryData = (jsonData, size = "") => {
            const linksTable = []
            if (jsonData.gallery) {
                jsonData.gallery.forEach((item) => {
                    const link = selectImageByType(item, size)
                    linksTable.push(link)
                })
            }

            return linksTable;
        }

        fetch(`${apiRoutesUrls.main}${selectEndpoitByType()}`)
            .then((res) => res.json())
            .then((json) => {
                setData(json);

                if (json !== null) {
                    if (type !== "klocki-introligatorskie") {
                        if (json.id_manuscript_rism_parent === "0") {
                            setGalleryData(createGalleryData(json));
                            setGalleryThumbData(createGalleryData(json, "medium"));
                        } else {
                            setGalleryData([]);
                            setGalleryThumbData([]);
                        }
                    } else {
                        setGalleryData(createGalleryData(json));
                        setGalleryThumbData(createGalleryData(json, "medium"));
                    }
                } else {
                    window.location.href = `/${lang}/error`;
                }
            })
    }, [type, id, lang, children]);

    useEffect(() => {
        window.addEventListener('keydown', keyDown);
        return () => window.removeEventListener('keydown', keyDown);
    })

    useEffect(() => {
        setTimeout(() => {
            if (window.gtag) {
                window.gtag('event', 'page_view')
            }
        }, 300)
        if (!page) {
            history.push(`/${lang}/lokalizacje/galeria/${type}/${oldId}/${1}${children ? `/${children}` : ''}`)
        }
    }, [history, type, oldId, lang, page, children])

    useEffect(() => {
        if (prevId !== id) {
            setData(null)
        }
    }, [id, prevId]);

    useEffect(() => {
        if (data) {
            fetch(`https://humdrum.nifc.pl/rism:${data.rism_id}.json`)
                .then((res) => res.json())
                .then((json) => {
                    if (json) {
                        let links = []
                        json.forEach((item) => {
                            if (item.cenid) {
                                links.push({ author: item.Author, title: item.Title, subtitle: item.Subtitle, id: item.cenid })
                            }
                        })

                        if (links.length > 0) {
                            setHumdrumLink(links)
                        }
                    }
                })
        }
    }, [data])

    return (
        <>
            <Helmet>
                <title>{translation.helmet.gallery} {`"${data ? data.standardized_title ? data.standardized_title : `${data.material_examined}` : ''}"`} - {translation.helmet.main}</title>
            </Helmet>
            <LoaderPage data={page !== null && data !== null} />
            {page !== null && data !== null && galleryData !== null && galleryThumbData !== null ?
                <div className={`${styles.wrapper} ${galleryData.length === 0 && galleryThumbData.length === 0 ? styles.wrapperNoGallery : ""}`}>
                    <div className={`${styles.container} ${galleryData.length === 0 && galleryThumbData.length === 0 ? styles.containerNoGallery : ''}`}>
                        <div className={`${styles.mobileDescriptionWrapper} ${galleryData.length !== 0 && galleryThumbData.length !== 0 ? isActive ? styles.active : '' : styles.active}`}>
                            {galleryData.length !== 0 && galleryThumbData.length !== 0 ?
                                <div className={styles.mobileDescriptionArrow}>
                                    <button
                                        className={`${styles.box} ${isActive ? '' : styles.right}`}
                                        onClick={() => {
                                            handleToggle();
                                        }}
                                        title={`${isActive ? translation.wcagLabels.gallery.hideData : translation.wcagLabels.gallery.showData}`}
                                        aria-label={`${isActive ? translation.wcagLabels.gallery.hideData : translation.wcagLabels.gallery.showData}`}
                                    >
                                        <div className={styles.mobileArrow} />
                                    </button>
                                </div>
                                : null}
                            <div className={`${styles.mobileDescriptionBox}`}>
                                <div className={styles.description}>
                                    {data.source_or_owner ?
                                        <Link to={`/${lang}/lokalizacje/${data.source_or_owner.id}-${slug(data.source_or_owner.name)}`} className={`${styles.titleBox} ${styles.ownerBox}`}
                                            title={`${translation.wcagLabels.lokalizacje.goToList} - "${data.source_or_owner.name}"`}
                                            aria-label={`${translation.wcagLabels.lokalizacje.goToList} - "${data.source_or_owner.name}"`}
                                        >
                                            <div className={styles.ownerImage} style={{ backgroundImage: `url("${apiRoutesUrls.cmsCategoryArchivesImage}/${data.source_or_owner.id}/${data.source_or_owner.image}")` }} />
                                            <h1 className={`${styles.titleBoxField} ${styles.ownerText}`}>
                                                {data.source_or_owner.name}
                                            </h1>
                                        </Link>
                                        : null}
                                    {data.author_or_institution ?
                                        data.composer[0] ?
                                            <div className={styles.titleBoxHorizontal}>
                                                <div className={styles.titleBox}>
                                                    <h2 className={styles.titleBoxField}>
                                                        {translation.galleryPage.descrition.author_or_institution}
                                                    </h2>
                                                    <Link to={createSearchLink(data.author_or_institution, 5)} className={styles.smallText}
                                                        title={`${translation.wcagLabels.wyszukiwanie.goToObjectsAuthor} - "${data.author_or_institution}"`}
                                                        aria-label={`${translation.wcagLabels.wyszukiwanie.goToObjectsAuthor} - "${data.author_or_institution}"`}
                                                    >
                                                        {data.author_or_institution}
                                                    </Link>
                                                </div>
                                                <a href={linkToCommposer(data.composer[0])} className={styles.link} aria-label={data.composer[0].name} title={data.composer[0].name}> </a>
                                            </div> :
                                            <div className={styles.titleBox}>
                                                <h2 className={styles.titleBoxField}>
                                                    {translation.galleryPage.descrition.author_or_institution}
                                                </h2>
                                                <Link to={createSearchLink(data.author_or_institution, 5)} className={styles.smallText}
                                                    title={`${translation.wcagLabels.wyszukiwanie.goToObjectsAuthor} - "${data.author_or_institution}"`}
                                                    aria-label={`${translation.wcagLabels.wyszukiwanie.goToObjectsAuthor} - "${data.author_or_institution}"`}
                                                >
                                                    {data.author_or_institution}
                                                </Link>
                                            </div>
                                        : null}
                                    {data.title_on_source ?
                                        data.composer[0] ?
                                            <div className={styles.titleBox}>
                                                <div className={styles.titleBoxHorizontal}>
                                                    <h2 className={styles.titleBoxField}>
                                                        {translation.galleryPage.descrition.title_on_source}
                                                    </h2>
                                                    <a href={linkToComposition(data.composer[0])} className={styles.link} aria-label={"not finished yet"} title={"not finished yet"}> </a>
                                                </div>
                                                <span className={styles.smallText}>
                                                    {data.title_on_source}
                                                </span>
                                            </div> :
                                            <div className={styles.titleBox}>
                                                <h2 className={styles.titleBoxField}>
                                                    {translation.galleryPage.descrition.title_on_source}
                                                </h2>
                                                <span className={styles.smallText}>
                                                    {data.title_on_source}
                                                </span>
                                            </div>
                                        : null}
                                    {data.variant_title_on_source ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.variant_title_on_source}
                                            </h2>
                                            <span className={styles.smallText}>
                                                {data.variant_title_on_source}
                                            </span>
                                        </div>
                                        : null}
                                    {data.standardized_title ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.standardized_title}
                                            </h2>
                                            <Link to={createSearchLink(data.standardized_title, 10)} className={styles.smallText}
                                                title={`${translation.wcagLabels.wyszukiwanie.goToObjectsStandardizedTitle} - "${data.standardized_title}"`}
                                                aria-label={`${translation.wcagLabels.wyszukiwanie.goToObjectsStandardizedTitle} - "${data.standardized_title}"`}
                                            >
                                                {data.standardized_title}
                                            </Link>
                                        </div>
                                        : null}
                                    {!data.standardized_title && data.material_examined ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.material_examined}
                                            </h2>
                                            <Link to={createSearchLink(data.material_examined, 10)} className={styles.smallText}
                                                title={`${translation.wcagLabels.wyszukiwanie.goToObjectsStandardizedTitle} - "${data.material_examined}"`}
                                                aria-label={`${translation.wcagLabels.wyszukiwanie.goToObjectsStandardizedTitle} - "${data.material_examined}"`}
                                            >
                                                {data.material_examined}
                                            </Link>
                                        </div>
                                        : null}
                                    {data.date_2 ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.date_2}
                                            </h2>
                                            <span className={styles.smallText}>
                                                {data.date_2}
                                            </span>
                                        </div>
                                        : null}
                                    {/*{data.place_of_storage_institution ?*/}
                                    {/*    <div className={styles.nameBox}>*/}
                                    {/*        <span className={styles.nameBoxField}>*/}
                                    {/*            {data.place_of_storage_institution}*/}
                                    {/*        </span>*/}
                                    {/*    </div>*/}
                                    {/*    : null}*/}
                                    {data.opus_number ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.opus_number}
                                            </h2>
                                            <span className={styles.smallText}>
                                                {data.opus_number}
                                            </span>
                                        </div>
                                        : null}
                                    {data.voice_or_instrument ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.voice_or_instrument}
                                            </h2>
                                            <span className={styles.smallText}>
                                                {data.voice_or_instrument}
                                            </span>
                                        </div>
                                        : null}
                                    {data.publisher_or_copyist ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.publisher_or_copyist}
                                            </h2>
                                            <span className={styles.smallText}>
                                                {data.publisher_or_copyist}
                                            </span>
                                        </div>
                                        : null}
                                    {data.place ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.place}
                                            </h2>
                                            <span className={styles.smallText}>
                                                {data.place}
                                            </span>
                                        </div>
                                        : null}
                                    {data.publisher_or_plate_number ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.publisher_or_plate_number}
                                            </h2>
                                            <span className={styles.smallText}>
                                                {data.publisher_or_plate_number}
                                            </span>
                                        </div>
                                        : null}
                                    {data.location_of_printer ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.location_of_printer}
                                            </h2>
                                            <span className={styles.smallText}>
                                                {data.location_of_printer}
                                            </span>
                                        </div>
                                        : null}
                                    {data.name_of_printer ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.name_of_printer}
                                            </h2>
                                            <span className={styles.smallText}>
                                                {data.name_of_printer}
                                            </span>
                                        </div>
                                        : null}
                                    {data.format_or_extent ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.format_or_extent}
                                            </h2>
                                            <span className={styles.smallText}>
                                                {data.format_or_extent}
                                            </span>
                                        </div>
                                        : null}
                                    {data.dimensions ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.dimensions}
                                            </h2>
                                            <span className={styles.smallText}>
                                                {data.dimensions}
                                            </span>
                                        </div>
                                        : null}
                                    {data.authors_and_functions_array ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.authors_and_functions}
                                            </h2>
                                            <p className={`${styles.smallText} ${styles.smallTextRow}`}>
                                                {data.authors_and_functions_array.map((item, index) => {
                                                    return (
                                                        <span key={index}>
                                                            <Link to={createSearchLink(item.name, 22)} className={styles.smallText}
                                                                title={`${translation.wcagLabels.wyszukiwanie.goToObjectsRelated} - "${item.name}"`}
                                                                aria-label={`${translation.wcagLabels.wyszukiwanie.goToObjectsRelated} - "${item.name}"`}
                                                            >
                                                                {`${item.name} (${item.function})`}
                                                            </Link>
                                                            {index < data.authors_and_functions_array.length
                                                                ? <span>;&nbsp;</span>
                                                                : null
                                                            }
                                                        </span>
                                                    )
                                                })}
                                            </p>
                                        </div>
                                        : null}
                                    {data.subject_heading && data.subject_heading !== " " ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.subject_heading}
                                            </h2>
                                            <div className={`${styles.smallText} ${styles.blockFlex} ${styles.blockFlexWrap}`}>
                                                {data.subject_heading.split(';').map((item, index) => {
                                                    return (
                                                        item.length > 1 ?
                                                            <div key={index} className={styles.blockFlex}>
                                                                <Link to={createSearchLink(item, 24)} className={styles.smallText}
                                                                    title={`${translation.wcagLabels.wyszukiwanie.goToObjectsSubjectHeading} - "${item}"`}
                                                                    aria-label={`${translation.wcagLabels.wyszukiwanie.goToObjectsSubjectHeading} - "${item}"`}
                                                                >
                                                                    {item}
                                                                </Link>
                                                                {index < data.subject_heading.split(';').length - 1
                                                                    ? <span>,&nbsp;</span>
                                                                    : null
                                                                }
                                                            </div>
                                                            : null

                                                    )
                                                })}
                                            </div>
                                        </div>
                                        : null}
                                    {data.liturgical_festival ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.liturgical_festival}
                                            </h2>
                                            <span className={styles.smallText}>
                                                {data.liturgical_festival}
                                            </span>
                                        </div>
                                        : null}
                                    {data.language_of_text ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.language_of_text}
                                            </h2>
                                            <span className={styles.smallText}>
                                                {data.language_of_text}
                                            </span>
                                        </div>
                                        : null}
                                    {data.general_note ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.general_note}
                                            </h2>
                                            <span className={styles.smallTextGeneral} dangerouslySetInnerHTML={{ __html: data.general_note }} />
                                        </div>
                                        : null}
                                    {data.binding_note ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.binding_note}
                                            </h2>
                                            <span className={styles.smallText} dangerouslySetInnerHTML={{ __html: data.binding_note }} />
                                        </div>
                                        : null}
                                    {data.provenance_information ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.provenance_information}
                                            </h2>
                                            <span className={styles.smallText} dangerouslySetInnerHTML={{ __html: data.provenance_information }} />
                                        </div>
                                        : null}
                                    {data.signature ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.signature}
                                            </h2>
                                            <span className={styles.smallText}>
                                                {data.signature}
                                            </span>
                                        </div>
                                        : null}
                                    {data.library_siglum ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.library_siglum}
                                            </h2>
                                            <span className={styles.smallText}>
                                                {data.library_siglum}
                                            </span>
                                        </div>
                                        : null}
                                    {data.rism_id ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.rism_id}
                                            </h2>
                                            <a className={styles.smallText} href={`https://opac.rism.info/metaopac/search?View=rism&id=${data.rism_id}&Language=en`} target={"_blank"} title={translation.titleLink.rism} aria-label={translation.titleLink.rism} rel={"noreferrer"}>{data.rism_id}</a>
                                        </div>
                                        : null}
                                    {humdrumLink ?
                                        <div className={styles.titleBox}>
                                            <h2 className={styles.titleBoxField}>
                                                {translation.galleryPage.descrition.humdrumLink.title}
                                            </h2>
                                            {humdrumLink.map((item, index) => {
                                                return (
                                                    <a key={index} className={`${styles.smallText} ${styles.marginBottom}`}
                                                        href={`https://polishscores.org/?id=${item.id}`}
                                                        target={"_blank"} rel={"noreferrer"}
                                                        title={`${translation.wcagLabels.wyszukiwanie.goToObjectsHumdrum} - "${item.author}: ${item.title}${item.subtitle ? ` - ${item.subtitle}` : ''}"`}
                                                        aria-label={`${translation.wcagLabels.wyszukiwanie.goToObjectsHumdrum} - "${item.author}: ${item.title}${item.subtitle ? ` - ${item.subtitle}` : ''}"`}
                                                    >
                                                        {`${item.author}: ${item.title}${item.subtitle ? ` - ${item.subtitle}` : ''}`}
                                                    </a>
                                                )
                                            })}
                                        </div>
                                        : null}
                                    {/* data.txt ?
                                        <div className={styles.titleBox}>
                                            <span className={styles.titleBoxField} dangerouslySetInnerHTML={{__html: data.txt}}/>
                                        </div>
                                        : null */}
                                    <div className={styles.errorWrapper}>
                                        <button type={"button"} className={styles.errorButton} onClick={() => { setModalOpened(!modalOpened); formFocus(); }} title={translation.galleryPage.error.errorTextAria} aria-label={translation.galleryPage.error.errorTextAria}>
                                            <div className={styles.errorImg} />
                                            <div className={styles.errorText}>{translation.galleryPage.error.errorText}</div>
                                        </button>
                                    </div>
                                    {(type === "klocki-introligatorskie" && (data.musical_print_source || data.manuscripts)) || ((type === "rekopisy" || type === "druki-muzyczne") && data.children.length > 0)
                                        ? <button type={"button"} className={styles.showContextButton} onClick={() => {
                                            setShowContent(!showContent);
                                            handleToggleMobile();
                                        }}>{translation.galleryPage.showContent.buttonEnter}</button>
                                        : null
                                    }
                                    {data.funded_scans ?
                                        data.funded_scans.symbol !== "BRAK" ?
                                            <div className={styles.categoryBox}>
                                                <span className={styles.categoryBoxField}>
                                                    {data.funded_scans.name}
                                                </span>
                                            </div>
                                            : null
                                        : null}
                                    <div className={styles.logosWrapper}>
                                        <div style={{ backgroundImage: `url("${lang === "pl" ? logoFundusze : logoFundusze_en}")` }} />
                                        <div style={{ backgroundImage: `url("${lang === "pl" ? logoPolska : logoPolska_en}")` }} />
                                        <div style={{ backgroundImage: `url("${lang === "pl" ? logoMinisterstwo : logoMinisterstwo_en}")` }} />
                                        <div style={{ backgroundImage: `url("${lang === "pl" ? logoUe : logoUe_en}")` }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {galleryData !== null && galleryThumbData !== null
                            ? galleryData.length && galleryThumbData.length ?
                                <Gallery
                                    galleryData={galleryData}
                                    galleryThumbData={galleryThumbData}
                                    data={data}
                                    isActiveMenu={isActive}
                                />
                                : null
                            : null
                        }
                        <div className={styles.close} onClick={handleBack} tabIndex={0} title={translation.wcagLabels.gallery.close} aria-label={translation.wcagLabels.gallery.close} />
                        {(type === "klocki-introligatorskie" && (data.musical_print_source || data.manuscripts)) || ((type === "rekopisy" || type === "druki-muzyczne") && data.children.length > 0) ?
                            <div className={`${styles.contentContainer} ${showContent ? styles.contentContainerShow : ''}`}>
                                <div className={styles.contentWrapper}>
                                    <div className={styles.close2Wrapper}>
                                        <button type={"button"} className={styles.close2} onClick={() => setShowContent(!showContent)}></button>
                                    </div>
                                    {data.children || data.musical_print_source || data.manuscripts
                                        ? <h2 className={styles.type}>{`${type === "klocki-introligatorskie" ? translation.galleryPage.showContent.type["1"] : translation.galleryPage.showContent.type["2"]}`}</h2>
                                        : null
                                    }
                                    {type === "klocki-introligatorskie" ?
                                        <h3 className={styles.title}>
                                            {`${data.material_examined ? `${data.material_examined}` : ''}`}
                                            <span className={styles.rismText}>({data.rism_id})<a href={`https://opac.rism.info/metaopac/search?View=rism&id=${data.rism_id}&Language=en`} target={"_blank"} title={translation.titleLink.rism} aria-label={translation.titleLink.rism} rel={"noreferrer"}><span className={styles.linkIcon}></span></a></span>
                                        </h3>
                                        : null
                                    }
                                    <ol>
                                        {data.musical_print_source ?
                                            <li>
                                                <div className={styles.firstTitle}>{translation.galleryPage.showContent.listType["1"]}</div>
                                                <div className={`${styles.list} ${styles.firstList}`}>
                                                    {data.musical_print_source.map((item, index) => {
                                                        return (
                                                            <div className={styles.point} key={index}>
                                                                <div className={styles.pointWrapper}>
                                                                    <span>
                                                                        {`1.${index + 1}.`}
                                                                    </span>
                                                                    <p className={styles.first} >
                                                                        {item.id_musicalprint_source
                                                                            ? <a href={`${setSelected(item, "musicalprint_source")}`} className={`${styles.selectButton} ${!item.id_musicalprint_source ? styles.selectButtonNoSelect : ""}`} title={item.id_musicalprint_source ? translation.titleLink.children : ''} aria-label={item.id_musicalprint_source ? translation.titleLink.children : ''}>{`${item.name ? `${item.name}, ` : ''}${item.standardized_title ? item.standardized_title : item.main_standardized_title}`}</a>
                                                                            : <span className={`${styles.selectButton} ${!item.id_musicalprint_source ? styles.selectButtonNoSelect : ""}`} title={item.id_musicalprint_source ? translation.titleLink.children : ''} aria-label={item.id_musicalprint_source ? translation.titleLink.children : ''}>{`${item.name ? `${item.name}, ` : ''}${item.standardized_title ? item.standardized_title : item.main_standardized_title}`}</span>
                                                                        }
                                                                        {item.rism_id_number
                                                                            ? <span className={styles.rismText}>(RISM ID: {item.rism_id_number})<a href={`https://opac.rism.info/metaopac/search?View=rism&id=${item.rism_id_number}&Language=en`} target={"_blank"} title={translation.titleLink.rism} aria-label={translation.titleLink.rism} rel={"noreferrer"}><span className={styles.linkIcon}></span></a></span>
                                                                            : null
                                                                        }
                                                                    </p>
                                                                </div>
                                                                {item.children.length > 0 ?
                                                                    <div className={styles.list}>
                                                                        {item.children.map((subItem, subIndex) => {
                                                                            return (
                                                                                <div className={styles.point} key={subIndex}>
                                                                                    <div className={styles.pointWrapper}>
                                                                                        <span>
                                                                                            {`1.${index + 1}.${subIndex + 1}.`}
                                                                                        </span>
                                                                                        <p className={styles.first}>
                                                                                            {subItem.id_musicalprint_source_rism
                                                                                                ? <a href={`${setSelected(subItem, "musicalprint_source", subItem.id_musicalprint_source_rism ? subItem.id_musicalprint_source_rism : null)}`} className={`${styles.selectButton} ${!subItem.id_musicalprint_source ? styles.selectButtonNoSelect : ""}`} title={subItem.id_musicalprint_source ? translation.titleLink.children : ''} aria-label={subItem.id_musicalprint_source ? translation.titleLink.children : ''}>{`${subItem.name ? `${subItem.name}, ` : ''}${subItem.standardized_title ? subItem.standardized_title : subItem.main_standardized_title}`}</a>
                                                                                                : <span className={`${styles.selectButton} ${!subItem.id_musicalprint_source ? styles.selectButtonNoSelect : ""}`} title={subItem.id_musicalprint_source ? translation.titleLink.children : ''} aria-label={subItem.id_musicalprint_source ? translation.titleLink.children : ''}>{`${subItem.name ? `${subItem.name}, ` : ''}${subItem.standardized_title ? subItem.standardized_title : subItem.main_standardized_title}`}</span>
                                                                                            }
                                                                                            {subItem.rism_id_number
                                                                                                ? <span className={styles.rismText}>(RISM ID: {subItem.rism_id_number})<a href={`https://opac.rism.info/metaopac/search?View=rism&id=${subItem.rism_id_number}&Language=en`} target={"_blank"} title={translation.titleLink.rism} aria-label={translation.titleLink.rism} rel={"noreferrer"}><span className={styles.linkIcon}></span></a></span>
                                                                                                : null
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                    : null
                                                                }
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </li>
                                            : null
                                        }
                                        {data.manuscripts ?
                                            <li>
                                                <div className={styles.firstTitle}>{translation.galleryPage.showContent.listType["2"]}</div>
                                                <div className={`${styles.list} ${styles.firstList}`}>
                                                    {data.manuscripts.map((item, index) => {
                                                        return (
                                                            <div className={styles.point} key={index}>
                                                                <div className={styles.pointWrapper}>
                                                                    <span>
                                                                        {`2.${index + 1}.`}
                                                                    </span>
                                                                    <p className={styles.first}>
                                                                        {item.id_manuscript
                                                                            ? <a href={setSelected(item, "manuscripts")} className={`${styles.selectButton} ${!item.id_manuscript ? styles.selectButtonNoSelect : ''}`} type={"button"} title={item.id_manuscript ? translation.titleLink.children : ''} aria-label={item.id_manuscript ? translation.titleLink.children : ''}>{`${item.name ? `${item.name}, ` : ''}${item.standardized_title ? item.standardized_title : item.main_standardized_title}`}</a>
                                                                            : <span className={`${styles.selectButton} ${!item.id_manuscript ? styles.selectButtonNoSelect : ''}`} title={item.id_manuscript ? translation.titleLink.children : ''} aria-label={item.id_manuscript ? translation.titleLink.children : ''}>{`${item.name ? `${item.name}, ` : ''}${item.standardized_title ? item.standardized_title : item.main_standardized_title}`}</span>
                                                                        }
                                                                        {item.rism_id_number
                                                                            ? <span className={styles.rismText}>(RISM ID: {item.rism_id_number})<a href={`https://opac.rism.info/metaopac/search?View=rism&id=${item.rism_id_number}&Language=en`} target={"_blank"} title={translation.titleLink.rism} aria-label={translation.titleLink.rism} rel={"noreferrer"}><span className={styles.linkIcon}></span></a></span>
                                                                            : null
                                                                        }
                                                                    </p>
                                                                </div>
                                                                {item.children.length > 0 ?
                                                                    <div className={styles.list}>
                                                                        {item.children.map((subItem, subIndex) => {
                                                                            return (
                                                                                <div className={styles.first} key={subIndex}>
                                                                                    <div className={styles.pointWrapper}>
                                                                                        <span>
                                                                                            {`2.${index + 1}.${subIndex + 1}.`}
                                                                                        </span>
                                                                                        <p className={styles.first}>
                                                                                            {subItem.id_manuscript_rism
                                                                                                ? <a href={`${setSelected(subItem, "manuscripts", subItem.id_manuscript_rism ? subItem.id_manuscript_rism : null)}`} className={`${styles.selectButton} ${!subItem.id_manuscript ? styles.selectButtonNoSelect : ""}`} title={subItem.id_manuscript ? translation.titleLink.children : ''} aria-label={subItem.id_manuscript ? translation.titleLink.children : ''}>{`${subItem.name ? `${subItem.name}, ` : ''}${subItem.standardized_title ? subItem.standardized_title : subItem.main_standardized_title}`}</a>
                                                                                                : <span className={`${styles.selectButton} ${!subItem.id_manuscript ? styles.selectButtonNoSelect : ""}`} title={subItem.id_manuscript ? translation.titleLink.children : ''} aria-label={subItem.id_manuscript ? translation.titleLink.children : ''}>{`${subItem.name ? `${subItem.name}, ` : ''}${subItem.standardized_title ? subItem.standardized_title : subItem.main_standardized_title}`}</span>
                                                                                            }
                                                                                            {subItem.rism_id_number
                                                                                                ? <span className={styles.rismText}>(RISM ID: {subItem.rism_id_number})<a href={`https://opac.rism.info/metaopac/search?View=rism&id=${subItem.rism_id_number}&Language=en`} target={"_blank"} title={translation.titleLink.rism} aria-label={translation.titleLink.rism} rel={"noreferrer"}><span className={styles.linkIcon}></span></a></span>
                                                                                                : null
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                    : null
                                                                }
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </li>
                                            : null
                                        }
                                        {type === "rekopisy" || type === "druki-muzyczne" ?
                                            <li>
                                                <h3 className={styles.title}>
                                                    {`${data.author_or_institution ? `${data.author_or_institution}, ` : ''}`}{data.standardized_title ? `${data.standardized_title} ` : data.material_examined ? `${data.material_examined} ` : ''}
                                                    <span className={styles.rismText}>({data.rism_id})<a href={`https://opac.rism.info/metaopac/search?View=rism&id=${data.rism_id}&Language=en`} target={"_blank"} title={translation.titleLink.rism} aria-label={translation.titleLink.rism} rel={"noreferrer"}><span className={styles.linkIcon}></span></a></span>
                                                </h3>
                                                <div className={`${styles.list} ${styles.firstList}`}>
                                                    {data.children.map((item, index) => {
                                                        return (
                                                            <div className={styles.point} key={index}>
                                                                <div className={styles.pointWrapper}>
                                                                    <span>
                                                                        {`${index + 1}.`}
                                                                    </span>
                                                                    <p className={styles.first}>
                                                                        {(type === "druki-muzyczne" ? item.id_musicalprint_source : item.id_manuscript_rism)
                                                                            ? <a href={`${setSelected(item, type === "druki-muzyczne" ? "musicalprint_source" : "manuscripts", (type === "druki-muzyczne" ? item.id_musicalprint_source_rism ? item.id_musicalprint_source_rism : null : item.id_manuscript_rism ? item.id_manuscript_rism : null))}`} className={`${styles.selectButton} ${!(type === "druki-muzyczne" ? item.id_musicalprint_source_rism ? item.id_musicalprint_source_rism : null : item.id_manuscript_rism ? item.id_manuscript_rism : null) ? styles.selectButtonNoSelect : ""}`} title={type === "druki-muzyczne" ? item.id_musicalprint_source_rism ? item.id_musicalprint_source_rism : null : item.id_manuscript_rism ? item.id_manuscript_rism : null ? translation.titleLink.children : ''} aria-label={type === "druki-muzyczne" ? item.id_musicalprint_source ? item.id_musicalprint_source : null : item.id_manuscript_rism ? item.id_manuscript_rism : null ? translation.titleLink.children : ''}>{`${item.name ? `${item.name}, ` : ''}${item.title_on_source ? item.title_on_source.includes(['[caption title:]', '[without title]']) ? item.title_on_source : item.standardized_title ? item.standardized_title : item.main_standardized_title : item.standardized_title ? item.standardized_title : item.main_standardized_title}`}</a>
                                                                            : <span className={`${styles.selectButton} ${!(type === "druki-muzyczne" ? item.id_musicalprint_source_rism ? item.id_musicalprint_source_rism : null : item.id_manuscript_rism ? item.id_manuscript_rism : null) ? styles.selectButtonNoSelect : ""}`} title={type === "druki-muzyczne" ? item.id_musicalprint_source_rism ? item.id_musicalprint_source_rism : null : item.id_manuscript_rism ? item.id_manuscript_rism : null ? translation.titleLink.children : ''} aria-label={type === "druki-muzyczne" ? item.id_musicalprint_source_rism ? item.id_musicalprint_source_rism : null : item.id_manuscript_rism ? item.id_manuscript_rism : null ? translation.titleLink.children : ''}>{`${item.name ? `${item.name}, ` : ''}${item.title_on_source ? item.title_on_source.includes(['[caption title:]', '[without title]']) ? item.title_on_source : item.standardized_title ? item.standardized_title : item.main_standardized_title : item.standardized_title ? item.standardized_title : item.main_standardized_title}`}</span>
                                                                        }
                                                                        {item.rism_id_number
                                                                            ? <span className={styles.rismText}>(RISM ID: {item.rism_id_number})<a href={`https://opac.rism.info/metaopac/search?View=rism&id=${item.rism_id_number}&Language=en`} target={"_blank"} title={translation.titleLink.rism} aria-label={translation.titleLink.rism} rel={"noreferrer"}><span className={styles.linkIcon}></span></a></span>
                                                                            : null
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </li>
                                            : null
                                        }
                                    </ol>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                    <ErrorFrom opened={modalOpened} setOpened={setModalOpened} />
                </div>
                : null}
            <Cookie />
        </>
    );
}

export default GalleryPage;
